import { useForm } from "react-hook-form";
import { BlockInputsStyled, BlockStyled, ButtonsBlockStyled, CreateDeveloperStyled } from "./styled";
import { ButtonStyled } from "../../../helperStyled/styledHelper";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooksStore";
import { setPopUp } from "../../../store/core/core";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./shema";
import TextInput from "../../Inputs/TextInput/TextInput";
import { PopUpKeys } from "../../../types/TypeCore";
import { TitlePopUpStyled } from "../PopCreateBuyDomen/styled";
import { toast } from "react-toastify";
import { getTrackers, sendTracker } from "../../../api/requestService";
import { setTrackersAll } from "../../../store/itemsSelect/itemsSelectSlice";
import { TrackerType } from "../../../types/TypePWA";
import ButtonItem from "../../ButtonItem/ButtonItem";

const PopCreateTracker = () => {
  const dispatch = useAppDispatch();

  const { handleSubmit, control } = useForm<Omit<TrackerType, 'id'>>({
    resolver: yupResolver(schema),
    defaultValues: {
      "name": '',
      "link": '',
    },
  });

  const { onChange } = useAppSelector(state => state.core.popUp);

  const onSubmit = async (data: Omit<TrackerType, 'id'>) => {
    try {
      const dataToFatch = {
        "name": data.name,
        "link": data.link,
      }

      const trackerNaw = await sendTracker(dataToFatch);

      !!onChange && onChange(trackerNaw.name);
      
      const tracksFatch = await getTrackers();

      dispatch(setTrackersAll(tracksFatch));

      toast.success('Tracker создан!');

      dispatch(setPopUp({popUpName: PopUpKeys.NONE,}));
    } catch {
      console.log('error create');
      toast.error('Ошибка создания Tracker!');
    }
  };

  return (
    <CreateDeveloperStyled onSubmit={handleSubmit(onSubmit)}>
      <BlockStyled>
        <TitlePopUpStyled>Создание Tracker</TitlePopUpStyled>

        <BlockInputsStyled>
          <TextInput
            control = {control}
            name = 'name'
            label = 'Название'
            isExit = {true}
          />

          <TextInput
            control = {control}
            name = 'link'
            label = 'Сайт'
            isExit = {true}
          />
        </BlockInputsStyled>

        <ButtonsBlockStyled>
          <ButtonStyled
            variant="white"
            type="reset"
            onClick={() => dispatch(setPopUp({popUpName: PopUpKeys.NONE,}))}
          >
            Отмена
          </ButtonStyled>

          <ButtonItem variant='blue' buttonText='Создать' funcClick={handleSubmit(onSubmit)}/>
        </ButtonsBlockStyled>
      </BlockStyled>
    </CreateDeveloperStyled>
  );
};

export default PopCreateTracker;
