import { Control, FieldErrors, FieldValues, useFieldArray } from "react-hook-form";
import { ButtonAddStyled, ErrorValidationStyled, ListItemsWidthStyled, ListNameItemStyled, ListNamesStyled, ListRowItemStyled, ListRowStyled, NameStyled, PushMassageStyled } from "./styled";
import { listNames } from "./PushListMassageApi";
import { ButtonStyled } from "../../../../helperStyled/styledHelper";
import {SelectControlInput} from "../../../../components/Inputs/SelectInput/SelectInput";
import { pushMessageLanguage } from "../../../../utils/consts";
import SwitchInput from "../../../../components/Inputs/SwitchInput/SwitchInput";
import TextAreaInput from "../../../../components/Inputs/TextAreaInput/TextAreaInput";
import PhotoInput from "../../../../components/Inputs/PhotoInput/PhotoInput";
import { CreatePushType } from "../../../../types/PushTupe";

type Props = {
  control: Control<CreatePushType, any>;
  errors: FieldErrors<Omit<CreatePushType, "id">>;
}

const PushListMessage: React.FC<Props> = ({control, errors}) => {
  const defaultValue = {
    language: 'en | English',
    title: '',
    text: '',
    image: [],
    is_default: false,
  }

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'push_languages',
  });

 return (
  <PushMassageStyled>
    <NameStyled>Сообщение</NameStyled>

    <ListNamesStyled>
      {listNames.map((nam) => (
        <ListNameItemStyled css={nam.styleList} key={nam.id}>
          {nam.title}
        </ListNameItemStyled>
      ))}
    </ListNamesStyled>

    <ListItemsWidthStyled>
      {!!fields.length && fields.map((item, index) => (
        <ListRowStyled key={item.id}>
          <ListRowItemStyled css={listNames[0].style}>
            <SelectControlInput width="190px" control={control} name={`push_languages.${index}.language`} label="" items={pushMessageLanguage} isAutocomplete={true}/>
          </ListRowItemStyled>

          <ListRowItemStyled css={listNames[1].style}>
            <SwitchInput control={control} name={`push_languages.${index}.is_default`} label='' isNotPaddingLeft={true}/>
          </ListRowItemStyled>

          <ListRowItemStyled css={listNames[2].style}>
            <TextAreaInput control={control} name={`push_languages.${index}.title`} label='' variantWidth='verySmall' isImgPlaceHolder={false}/>
          </ListRowItemStyled>

          <ListRowItemStyled css={listNames[3].style}>
            <TextAreaInput control={control} name={`push_languages.${index}.text`} label='' variantWidth='verySmall' isImgPlaceHolder={false}/>
          </ListRowItemStyled>

          <ListRowItemStyled css={listNames[4].style}>
            <PhotoInput control={control} name={`push_languages.${index}.image`} label='Иконка' isMulti={false}/>
          </ListRowItemStyled>

          <ListRowItemStyled css={listNames[5].style}>
            <ButtonStyled variant="blue">Перевести</ButtonStyled>

            <ButtonStyled variant="red" onClick={() => remove(index)}>Удалить</ButtonStyled>
          </ListRowItemStyled>
        </ListRowStyled>
      ))}
    </ListItemsWidthStyled>

    {errors.push_languages && (
      <ErrorValidationStyled>
        {errors.push_languages.message && <>{errors.push_languages.message}</>}
      </ErrorValidationStyled>
    )}

    <ButtonAddStyled type="button" variant="blue" onClick={() => append(defaultValue)}>Добавить</ButtonAddStyled>
  </PushMassageStyled>
 )
};

export default PushListMessage