import { SubmitHandler, useForm } from "react-hook-form";
import TextInput from "../../../../components/Inputs/TextInput/TextInput";
import {
  ConteinerInputStyled,
  FormBasicStyled,
  FormInputsStyled,
  InputBlockStyled,
  ItemsBlockStyled,
} from "./styled";
import {SelectControlInput} from "../../../../components/Inputs/SelectInput/SelectInput";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooksStore";
import { setEditBasics } from "../../../../store/aplicationsEdit/aplicationEdit";
import { NavigateOptionEdit } from "../../../../types/NavigateOption";
import ButtonsEdit from "../ButtonsEdit/ButtonsEdit";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./shema";
import { BasicsEditType } from "../../../../types/EditsTypes";
import {MultiSelectControl} from "../../../../components/Inputs/MultiSelectInput/MultiSelectInput";
import { PopUpKeys, SetPopUpType } from "../../../../types/TypeCore";
import { setPopUp } from "../../../../store/core/core";

type Props = {
  setNavEdit: React.Dispatch<React.SetStateAction<NavigateOptionEdit>>;
};

const EditBasic: React.FC<Props> = ({ setNavEdit }) => {
  const { category_idAll, trackersAll, domainsAll, tagsAll, pushSets } = useAppSelector(
    (state) => state.itemsSelect
  );

  const pushSetsIdNames = pushSets.map((item) => ({id: item.id, name: item.name}));
  const category_idNames = category_idAll.map((item) => item.name);
  const trackersAllName = trackersAll.map((item) => item.name);
  const domainsAllName = domainsAll.filter((item) => !item.is_used).map((item) => item.name);

  const dispatch = useAppDispatch();

  const { basicsEdit } = useAppSelector((state) => state.aplicationEdit) || {};

  const { handleSubmit, control } = useForm<BasicsEditType>({
    resolver: yupResolver(schema),
    defaultValues: basicsEdit,
  });

  const onSubmit: SubmitHandler<BasicsEditType> = async (data) => {
    try {
      const dataContainer: BasicsEditType = {
        internal_name: data.internal_name,
        tracker_id: data.tracker_id,
        domain_id: data.domain_id,
        category_id: data.category_id,
        tags: data.tags,
        push_set_id: data.push_set_id,
      };

      dispatch(setEditBasics(dataContainer));

      setNavEdit("description");
    } catch (error) {
      console.error("Error converting files to base64:", error);
    }
  };

  const setTagCreate = (onChange: (...event: any[]) => void, value: any) => {
    const creater: SetPopUpType = {
      popUpName: PopUpKeys.TAG,
      onChange: onChange,
      value: value,
    };

    dispatch(setPopUp(creater));
  };

  const setTrackerCreate = (onChange: (...event: any[]) => void) => {
    const creater: SetPopUpType = {
      popUpName: PopUpKeys.TRACKER,
      onChange: onChange,
    };

    dispatch(setPopUp(creater));
  };

  return (
    <FormBasicStyled onSubmit={handleSubmit(onSubmit)}>
      <FormInputsStyled>
        <ItemsBlockStyled>
          <InputBlockStyled>
            <ConteinerInputStyled>
              <TextInput
                control={control}
                name="internal_name"
                label="Внутренее название"
                isExit={true}
              />
            </ConteinerInputStyled>
          </InputBlockStyled>

          <InputBlockStyled>
            <ConteinerInputStyled>
              <SelectControlInput
                control={control}
                name="domain_id"
                label="Домен"
                items={domainsAllName}
              />
            </ConteinerInputStyled>
          </InputBlockStyled>

          <InputBlockStyled>
            <ConteinerInputStyled>
              <MultiSelectControl
                control={control}
                name="tags"
                label="Теги"
                items={tagsAll}
                isAdding={true}
                setCreate={setTagCreate}
              />
            </ConteinerInputStyled>
          </InputBlockStyled>
        </ItemsBlockStyled>

        <ItemsBlockStyled>
          <InputBlockStyled>
            <ConteinerInputStyled>
              <SelectControlInput
                control={control}
                name="category_id"
                label="Категория"
                items={category_idNames}
              />
            </ConteinerInputStyled>
          </InputBlockStyled>

          <InputBlockStyled>
            <ConteinerInputStyled>
              <SelectControlInput
                control={control}
                name="tracker_id"
                label="Трекер"
                items={trackersAllName}
                isAdding={true}
                setCreate={setTrackerCreate}
              />
            </ConteinerInputStyled>
          </InputBlockStyled>
        </ItemsBlockStyled>

        <ItemsBlockStyled>
          {/* <InputBlockStyled>
            <ConteinerInputStyled>
              <SelectControlInput
                control={control}
                name="typeWhite"
                label="Тип white"
                items={["white page"]}
              />
            </ConteinerInputStyled>
          </InputBlockStyled> */}

          <InputBlockStyled>
            <ConteinerInputStyled>
              <SelectControlInput
                control={control}
                name="push_set_id"
                label="PushSet"
                items={pushSetsIdNames}
              />
            </ConteinerInputStyled>
          </InputBlockStyled>
        </ItemsBlockStyled>
      </FormInputsStyled>

      <ButtonsEdit setNavEdit={setNavEdit} toPage="basic" isActive={false} />
    </FormBasicStyled>
  );
};

export default EditBasic;
